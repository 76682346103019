import React, { Component } from "react";

import "./SeasonPassInfoModal.sass";

class SeasonPassInfoModal extends Component {
  render() {
    return (
      <div className="season-pass-info-modal">
        <div className="season-pass-info-modal__body">
          <h3>{this.props.title}</h3>
          <img src={this.props.image} alt={this.props.imageAltText} />
          <p>{this.props.text}</p>
          {this.props.additionalText && (
            <p className="season-pass-info-modal__additionalText">
              {this.props.additionalText}
            </p>
          )}
          {this.props.link}
        </div>
      </div>
    );
  }
}

export default SeasonPassInfoModal;
