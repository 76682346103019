import React from "react";

import Icon from "../../UI/Icon/Icon";
import { IconButton } from "../../UI/IconButton/IconButton";
import { useShowActionsMenu } from "../../UI/ActionsMenu/hooks/useShowActionsMenu";

export const TransactionActions = ({
  maximizingOptions,
  transaction,
  showDeleteTransactionModal,
  showApproveTransactionModal,
  showEditTransactionModal,
  showRefundTicketsModal,
  resendTicketsHandler,
  resendInvoiceHandler,
  copyOfferLinkHandler,
  copyTicketLinkHandler,
  cancelOfferHandler,
  allowMaximize = true,
}) => {
  const [showMenuActions] = useShowActionsMenu();

  const handleAccordionClick = (e) => {
    if (allowMaximize) {
      e.target.parentNode.parentNode.parentNode.parentNode.classList.toggle(
        "active"
      );
    }
  };

  const notCanceled = !transaction.isOfferCanceled;

  const isOffer = transaction.isSavedTransaction;
  const isCreatedTransaction = transaction.paymentStatus === 0 && notCanceled;
  const isApprovedTransaction = transaction.paymentStatus === 1 && notCanceled;
  const isOpenOffer = isOffer && isCreatedTransaction;

  const canRefund =
    isApprovedTransaction &&
    transaction.paymentType === 1 &&
    !transaction.refunded;

  const actionButtonClassName = isCreatedTransaction
    ? "btn-green IconButton green"
    : canRefund
      ? "btn-lavender IconButton lavender"
      : "IconButton gray";

  const actionButtonIconName = isCreatedTransaction
    ? "approve"
    : canRefund
      ? "refund"
      : "refund-gray";

  const editButtonClassName = isOpenOffer
    ? "btn-primary IconButton primary"
    : "IconButton gray";

  const editButtonIconName = isOpenOffer ? "edit" : "edit-gray";

  const accordionClassName =
    "IconButton accordion-btn " +
    (allowMaximize
      ? "btn-primary"
      : "btn-default disable");

  const menuItems = [];

  if (!maximizingOptions) {
    if (isCreatedTransaction) {
      menuItems.push({
        text: "Aprrove transaction",
        onClick: showApproveTransactionModal,
        data: transaction,
      });
    } else if (canRefund) {
      menuItems.push({
        text: "Refund transaction",
        onClick: showRefundTicketsModal,
        data: transaction,
      });
    }

    if (isOpenOffer) {
      menuItems.push({
        text: "Edit",
        onClick: showEditTransactionModal,
        data: transaction,
      });
    }

    menuItems.push({
      text: "Delete",
      onClick: showDeleteTransactionModal,
      data: transaction,
      className: "menu-item-red",
    });
  }

  if (isOpenOffer) {
    menuItems.push({
      text: "Resend offer",
      onClick: resendInvoiceHandler,
      data: transaction,
    });

    menuItems.push({
      text: "Copy offer link",
      onClick: copyOfferLinkHandler,
      data: transaction,
    });
  }

  if (isApprovedTransaction) {
    menuItems.push({
      text: "Resend tickets",
      onClick: resendTicketsHandler,
      data: transaction,
    });

    menuItems.push({
      text: "Copy ticket link",
      onClick: copyTicketLinkHandler,
      data: transaction,
    });
  }

  if (isOpenOffer) {
    menuItems.push({
      text: "Cancel offer",
      onClick: cancelOfferHandler,
      data: transaction,
    });
  }

  const optionsClassName =
    menuItems.length > 0
      ? "btn-primary IconButton primary trigger-actions-menu"
      : "IconButton gray trigger-actions-menu";

  const optionsIconName = menuItems.length > 0 ? "options" : "options-gray";

  const menuOptions = {
    items: menuItems,
    minMenuHeight: 35 * menuItems.length,
    offsetY: 7,
  };

  return (
    <div className="buttons-block">
      {maximizingOptions && (
        <>
          <IconButton
            iconName="trash-red"
            className="btn-red IconButton red"
            onClick={(e) => {
              showDeleteTransactionModal(transaction);
            }}
          />
          <IconButton
            iconName={actionButtonIconName}
            className={actionButtonClassName}
            onClick={(e) => {
              if (isCreatedTransaction) {
                showApproveTransactionModal(transaction);
              }

              if (canRefund) {
                showRefundTicketsModal(transaction);
              }
            }}
          />
          <IconButton
            iconName={editButtonIconName}
            className={editButtonClassName}
            onClick={(e) => {
              if (isOpenOffer) {
                showEditTransactionModal(transaction);
              }
            }}
          />
        </>
      )}

      <IconButton
        iconName={optionsIconName}
        className={optionsClassName}
        onClick={showMenuActions(menuOptions)}
      />

      {maximizingOptions && (
        <button
          className={accordionClassName}
          onClick={(e) => {
            handleAccordionClick(e);
          }}
        >
          <Icon name={"chevron"} />
        </button>
      )}
    </div>
  );
};
