import React, { Component } from "react";
import { withRouter } from "react-router";

import AsyncSelect from "react-select/lib/Async";

import moment from "moment";
import swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import axios from "../../axios-instance";

import Dropzone from "../Upload/Dropzone/Dropzone";
import CheckBox from "../../components/UI/Checkbox/CheckBox";
import Container from "../../components/UI/Container/Container";

import TermsAndConditionsModal from "./TermsAndConditionsModal/TermsAndConditionsModal";

import { bankLogos, resURL, signedKeys } from "./constants";

import SeasonPassLogo from "../../images/season-pass-logo.svg";
import ThanksForYourPurchase from "../../images/thanks-for-your-purchase.svg";
import SomethingWentWrong from "../../images/something-went-wrong.svg";
import ThanksForYourRequest from "../../images/thanks-for-your-request.svg";
import PleaseWait from "../../images/please-wait-season-pass-modal.svg";
import Warning from "../../images/warning.svg";
import ArenaMap from "../../images/arena-map.png";

import {
  createMerchantSin,
  handleSelectPlace,
  renderTotal,
  getTotal,
} from "./helpers";

import "./SeasonPass.sass";
import ROUTES from "../../constants/routes";
import { showInfoModal, showErrorModal } from "../../modals";

const MySwal = withReactContent(swal);

class SeasonPass extends Component {
  state = {
    fields: {
      fullName: "",
      physicalAddress: "",
      city: "",
      postalCode: "",
      phoneNumber: "",
      buyerEmail: "",
      passType: "",
      area: "",
      row: "",
      seat: "",
      side: "",
      companyVatNumber: "",
    },
    transactionData: {
      orderId: "",
      paymentAmount: "",
      currencyCode: "",
      //TODO: should check
      sessionValidity: "2023-08-28T14:26:53.000Z",
      resURL: resURL,
      lang: "en",
      merchantName: "SIXTIX d.o.o",
      signedKeys: signedKeys,
    },
    initialField: {
      transactionData: {},
      fields: {},
    },
    price: "",
    fileUrl: null,
    termsAndConditionsChecked: false,
    submittBlocked: true,
    isRequestChanges: false,
    isCheckoutAsCompanyChecked: false,
  };

  formRef = React.createRef();

  setOrderDataToState = (data) => {
    let isCheckoutAsCompanyChecked = false;

    const transactionData = {
      ...this.state.transactionData,
      orderId: data.id,
      paymentAmount: getTotal(data.amount),
      currencyCode: data.currency,
      redirectURL: `${window.location.origin}${ROUTES.SEASON_PASS.replace(
        ":id",
        data.id
      )}?`,
    };

    const fields = {
      fullName: data.fullName,
      city: data.city,
      postalCode: data.postalCode,
      phoneNumber: data.phoneNumber,
      buyerEmail: data.buyerEmail,
      area: data.area,
      seat: data.seat,
      row: data.row,
      passType: data.passType,
      physicalAddress: data.physicalAddress,
      side: data.side,
      companyVatNumber: data.companyVatNumber,
    };

    transactionData.merchantSig = createMerchantSin(
      transactionData,
      transactionData.signedKeys
    );

    if (fields.companyVatNumber) {
      isCheckoutAsCompanyChecked = true;
    }

    const initialField = {
      fields: {
        fullName: data.fullName,
        phoneNumber: data.phoneNumber,
        buyerEmail: data.buyerEmail,
        passType: data.passType,
        area: data.area,
        seat: data.seat,
        row: data.row,
        side: data.side,
      },
      transactionData,
    };

    this.setState({
      fileUrl: data.fileUrl,
      transactionData,
      fields,
      isCheckoutAsCompanyChecked,
      initialField,
      price: data.amount,
    });
  };

  showPleaseWaitModal = () => {
    MySwal.fire({
      imageUrl: PleaseWait,
      title: "Please wait",
      text: "You will be redirected to the payment page",
      allowEscapeKey: false,
      customClass: "seasonpass__modal",
      allowOutsideClick: false,
      allowEnterKey: false,
      showConfirmButton: false,
    });
  };

  showRequestModal = () =>
    showInfoModal({
      image: ThanksForYourRequest,
      title: "Thanks for Your Request!",
      text: `We have received your request and will contact you shortly for more details`,
    });

  showTermsAndConditionsModal = (ev) => {
    ev.preventDefault();
    MySwal.fire({
      html: <TermsAndConditionsModal />,
      width: "64em",
      customClass: "seasonpass__modal",
    });
  };

  showConfirmationModal = () => {
    MySwal.fire({
      imageUrl: Warning,
      title: "Confirm Sending the Request",
      text: "Please note that this is an irreversible action",
      cancelButtonText: "Cancel",
      confirmButtonText: "Confirm",
      allowEscapeKey: false,
      customClass: "seasonpass__modal",
      allowOutsideClick: false,
      allowEnterKey: false,
      showConfirmButton: true,
      showCancelButton: true,
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        this.handleSendRequest();
      }
    });
  };

  showOpenSeatsMap = () => {
    MySwal.fire({
      imageUrl: ArenaMap,
      width: "86em",
      customClass: "seasonpass__modal",
    });
  };

  handleOrderStatuses = (data) => {
    if (!data.availableForSales) {
      this.showBlockModal();
      this.setState({ submittBlocked: true });
      return;
    }

    if (data.changesRequested) {
      this.showRequestModal();
      this.setState({ submittBlocked: true });
      return;
    }

    switch (data.paymentStatus) {
      //New
      case 0:
        this.setState({ submittBlocked: false });
        break;
      //Success
      case 1:
        showInfoModal({
          image: ThanksForYourPurchase,
          title: "Thanks for Your Purchase!",
          text: `We will send all the details to your inbox (${data.buyerEmail}) and look forward to meeting you`,
          additionalText: `(Payment Status: ${
            data.additionalPaymentStatus
          }, Payment Date: ${moment(data.paymentDate).format(
            "DD MMMM YYYY HH:mm"
          )}, Transaction Id: ${data.transactionId})`,
        });
        this.setState({ submittBlocked: true });
        break;
      //Failed
      case 2:
        showErrorModal({
          text: `It seems to have failed to complete the payment, please try again`,
          additionalText: `(Payment Status: ${
            data.additionalPaymentStatus
          }, Payment Date: ${moment(data.paymentDate).format(
            "DD MMMM YYYY HH:mm"
          )}, Transaction Id: ${data.transactionId})`,
        });
        this.setState({ submittBlocked: false });
        break;
      //Cancelled
      case 3:
        this.setState({ submittBlocked: false });
        break;
      default:
        this.setState({ submittBlocked: true });
        break;
    }
  };

  getOrderData = async () => {
    try {
      const { data } = await axios.get(
        `Payment/orders/${this.props.match.params.id}`
      );
      if (data) {
        this.handleOrderStatuses(data);
        this.setOrderDataToState(data);
      }
    } catch (e) {
      showErrorModal({
        text: `It seems to have failed to complete the payment, please try again`,
      });
      console.log(e);
    }
  };

  setIsRequestChanges = () => {
    let { isRequestChanges } = this.state;
    this.setState({ isRequestChanges: !isRequestChanges }, () => {
      if (!this.state.isRequestChanges) {
        const { fields, transactionData } = this.state.initialField;
        this.setState({
          fields: { ...this.state.fields, ...fields },
          transactionData: {
            ...this.state.transactionData,
            ...transactionData,
          },
        });
      }
    });
  };

  setTermsAndConditions = () => {
    const { termsAndConditionsChecked } = this.state;
    this.setState({ termsAndConditionsChecked: !termsAndConditionsChecked });
  };

  setCheckoutAsCompany = () => {
    const { isCheckoutAsCompanyChecked, fields } = this.state;

    this.setState({
      isCheckoutAsCompanyChecked: !isCheckoutAsCompanyChecked,
      fields: {
        ...fields,
        companyVatNumber: "",
      },
    });
  };

  handleChange = (name, value) => {
    if (name) {
      this.setState({ fields: { ...this.state.fields, [name]: value } });
    }
  };

  checkIsSubbmitDisabled = () => {
    const {
      isRequestChanges,
      submittBlocked,
      termsAndConditionsChecked,
      fields: { companyVatNumber, ...otherFields },
      fileUrl,
      isCheckoutAsCompanyChecked,
    } = this.state;

    let isSubbmitDisabled =
      submittBlocked ||
      !termsAndConditionsChecked ||
      !fileUrl ||
      !otherFields.city ||
      !otherFields.postalCode ||
      !otherFields.physicalAddress;

    if (isRequestChanges) {
      isSubbmitDisabled = !Object.keys(otherFields).every(
        (key) => !!otherFields[key]
      );

      return isCheckoutAsCompanyChecked
        ? isSubbmitDisabled || !companyVatNumber
        : isSubbmitDisabled;
    }

    return isCheckoutAsCompanyChecked
      ? isSubbmitDisabled || !companyVatNumber
      : isSubbmitDisabled;
  };

  showBlockModal = () => {
    MySwal.fire({
      allowEscapeKey: false,
      html: (
        <div style={{ textAlign: "left" }}>
          <p>Poštovani,</p>
          <p style={{ margin: "0" }}>Ovaj link više nije aktivan!</p>
          <p style={{ margin: "0" }}>
            Nažalost, niste obnovili svoje prošlogodišnje mesto.
          </p>
          <p>
            Od 14. septembra počinje FAZA 2 – ZAMENA koja traje četiri dana i u
            tom periodu moći ćete svoju prošlogodišnju sezonsku ulaznicu da
            zamenite za novo mesto i iskoristite pravo preče kupovine.
          </p>
          <p>Požurite, vaše staro mesto možda je i dalje slobodno!</p>
        </div>
      ),
      customClass: "seasonpass__modal",
      allowOutsideClick: false,
      allowEnterKey: false,
      showConfirmButton: false,
    });
  };

  componentDidMount() {
    this.getOrderData();
  }

  handleUploadFile = async (files) => {
    try {
      const file = files[0];
      const orderId =
        this.state.transactionData.orderId || this.props.match.params.id;

      const url = `/Payment/AttachFile/${orderId}`;

      const data = new FormData();
      data.append("file", file);
      const { data: fileUrl } = await axios.post(url, data);
      this.setState({
        fileUrl,
      });
    } catch (e) {
      console.log(e);
    }
  };

  handleDeleteImg = () => {
    this.setState({
      fileUrl: null,
    });
  };

  checkIsSelectDisabled = (name) => {
    const {
      fields: { area, row, passType, side },
      isRequestChanges,
    } = this.state;

    if (!isRequestChanges) {
      return true;
    }

    switch (name) {
      case "area":
        return !passType;
      case "row":
        return !passType || !area;
      case "side":
        return !passType || !area || !row;
      case "seat":
        return !passType || !area || !row || !side;
      default:
        return false;
    }
  };

  handleSendRequest = async () => {
    const {
      fields,
      transactionData: { orderId },
    } = this.state;

    const body = {
      order: {
        ...fields,
      },
    };

    try {
      const { data } = await axios.post(
        `Payment/orders/${orderId}/request-changes`,
        body
      );
      this.handleOrderStatuses(data);
      this.setOrderDataToState(data);
    } catch (e) {
      console.log(e);
      showErrorModal({
        text: `It seems to have failed to complete the payment, please try again`,
      });
    }
  };

  sendOrderData = async () => {
    const {
      transactionData: { orderId },
      fields: { city, physicalAddress, postalCode, companyVatNumber },
    } = this.state;

    const body = {
      city,
      physicalAddress,
      postalCode,
      companyVatNumber,
    };

    await axios.put(`Payment/orders/${orderId}`, body);
  };

  handleSubmit = async () => {
    try {
      this.showPleaseWaitModal();
      await this.sendOrderData();
      this.formRef.current.submit();
    } catch (e) {
      console.log(e);
      showErrorModal({
        text: `It seems to have failed to complete the payment, please try again`,
      });
    }
  };

  checkIsFieldDisabled = (name) => {
    const {
      isRequestChanges,
      initialField: { fields: initialFields },
    } = this.state;

    if (isRequestChanges) {
      return false;
    }

    if (name && !initialFields[name]) {
      return false;
    }

    return true;
  };

  render() {
    const {
      fields: {
        area,
        city,
        buyerEmail,
        fullName,
        physicalAddress,
        passType,
        phoneNumber,
        postalCode,
        row,
        seat,
        side,
        companyVatNumber,
      },
      fileUrl,
      transactionData,
      isRequestChanges,
      isCheckoutAsCompanyChecked,
      termsAndConditionsChecked,
      price,
    } = this.state;

    return (
      <Container className="seasonpass">
        <div className="seasonpass__wrapper">
          <div className="seasonpass__header-logo-wrapper">
            <img src={SeasonPassLogo} alt="Logo" />
          </div>
          <div className="seasonpass__header">
            <h2 className="seasonpass__header-title">Buy Season Pass</h2>
          </div>
          <form
            ref={this.formRef}
            action={process.env.REACT_APP_PAYMENT_LINK}
            method="post"
            className="seasonpass__form"
          >
            {Object.keys(transactionData).map((key) => (
              <input
                name={key}
                key={key}
                type="hidden"
                value={transactionData[key]}
              />
            ))}
            <label className="seasonpass__field">
              <span className="seasonpass__field-text">
                <span className="seasonpass__field-text-label">Full Name</span>
                {!fullName && (
                  <span className="seasonpass__field-text-required">
                    Required Field
                  </span>
                )}
              </span>
              <input
                className={`seasonpass__field-input ${
                  !isRequestChanges && "disabled"
                }`}
                disabled={this.checkIsFieldDisabled("fullName")}
                name="fullName"
                onChange={(e) => this.handleChange("fullName", e.target.value)}
                type="text"
                value={fullName}
              />
            </label>

            <label className="seasonpass__field">
              <span className="seasonpass__field-text">
                <span className="seasonpass__field-text-label">
                  Street and House Number
                </span>
                {!physicalAddress && (
                  <span className="seasonpass__field-text-required">
                    Required Field
                  </span>
                )}
              </span>
              <input
                className={`seasonpass__field-input ${
                  !isRequestChanges && "disabled"
                }`}
                name="physicalAddress"
                onChange={(e) =>
                  this.handleChange("physicalAddress", e.target.value)
                }
                type="text"
                value={physicalAddress}
              />
            </label>

            <div className="seasonpass__grouped-fields">
              <label className="seasonpass__field">
                <span className="seasonpass__field-text">
                  <span className="seasonpass__field-text-label">City</span>
                  {!city && (
                    <span className="seasonpass__field-text-required">
                      Required Field
                    </span>
                  )}
                </span>
                <input
                  className="seasonpass__field-input"
                  name="city"
                  onChange={(e) => this.handleChange("city", e.target.value)}
                  value={city}
                  type="text"
                />
              </label>

              <label className="seasonpass__field">
                <span className="seasonpass__field-text">
                  <span className="seasonpass__field-text-label">
                    Postal Code
                  </span>
                  {!postalCode && (
                    <span className="seasonpass__field-text-required">
                      Required Field
                    </span>
                  )}
                </span>
                <input
                  className="seasonpass__field-input"
                  name="postalCode"
                  onChange={(e) =>
                    this.handleChange("postalCode", e.target.value)
                  }
                  type="text"
                  value={postalCode}
                />
              </label>
            </div>

            <div className="seasonpass__grouped-fields">
              <label className="seasonpass__field">
                <span className="seasonpass__field-text">
                  <span className="seasonpass__field-text-label">
                    Phone Number
                  </span>
                  {!phoneNumber && (
                    <span className="seasonpass__field-text-required">
                      Required Field
                    </span>
                  )}
                </span>
                <input
                  className="seasonpass__field-input"
                  type="text"
                  name="phoneNumber"
                  disabled={this.checkIsFieldDisabled("phoneNumber")}
                  onChange={(e) =>
                    this.handleChange("phoneNumber", e.target.value)
                  }
                  value={phoneNumber}
                />
              </label>

              <label className="seasonpass__field">
                <span className="seasonpass__field-text">
                  <span className="seasonpass__field-text-label">
                    Buyer Email
                  </span>
                  {!buyerEmail && (
                    <span className="seasonpass__field-text-required">
                      Required Field
                    </span>
                  )}
                </span>
                <input
                  className="seasonpass__field-input"
                  disabled={this.checkIsFieldDisabled("buyerEmail")}
                  name="buyerEmail"
                  onChange={(e) =>
                    this.handleChange("buyerEmail", e.target.value)
                  }
                  type="text"
                  value={buyerEmail}
                />
              </label>
            </div>

            <div className="seasonpass__grouped-fields seasonpass__company-vat-number">
              <div className="seasonpass__field">
                <span className="seasonpass__field-text">
                  <span className="seasonpass__field-text-label">
                    Checkout as company
                  </span>
                </span>
                <CheckBox
                  checked={isCheckoutAsCompanyChecked}
                  id="checkout-as-company-checkbox"
                  name="checkout-as-company-checkbox"
                  onChange={this.setCheckoutAsCompany}
                >
                  <span>Checkout as company</span>
                </CheckBox>
              </div>

              {isCheckoutAsCompanyChecked && (
                <label className="seasonpass__field">
                  <span className="seasonpass__field-text">
                    <span className="seasonpass__field-text-label">
                      Company Vat Number
                    </span>
                    {!companyVatNumber && (
                      <span className="seasonpass__field-text-required">
                        Required Field
                      </span>
                    )}
                  </span>
                  <input
                    className="seasonpass__field-input"
                    disabled={!isCheckoutAsCompanyChecked}
                    name="companyVatNumber"
                    onChange={(e) =>
                      this.handleChange("companyVatNumber", e.target.value)
                    }
                    type="text"
                    value={companyVatNumber}
                  />
                </label>
              )}
            </div>

            {isRequestChanges ? (
              <button
                type="button"
                onClick={this.showOpenSeatsMap}
                className="seasonpass__seats-map"
              >
                <img src={ArenaMap} alt="Seats Map" />
              </button>
            ) : (
              <div className={`seasonpass__dropzone`}>
                <span className="seasonpass__field-text">
                  <span className="seasonpass__field-text-label">
                    Upload Season Pass
                  </span>
                  {!fileUrl && (
                    <span className="seasonpass__field-text-required">
                      Required Field
                    </span>
                  )}
                </span>
                <div className="seasonpass__dropzone-hero">
                  {fileUrl ? (
                    <div className="seasonpass__dropzone-image-wrapper">
                      <img
                        className="seasonpass__dropzone-image"
                        src={fileUrl}
                        alt="Uploaded"
                      />
                      <button
                        type="button"
                        className="seasonpass__dropzone-image-drop"
                        onClick={this.handleDeleteImg}
                      />
                    </div>
                  ) : (
                    <Dropzone
                      // accept="image/png, image/jpeg"
                      onFilesAdded={this.handleUploadFile}
                      onButtonEnable={() => {}}
                      unsupportedFileFormatHandle={() => {}}
                      onlyOneFileErrorHandle={() => {}}
                    />
                  )}
                </div>
                <span className="seasonpass__field-text">
                  <span className="seasonpass__field-text-label">
                    Please note that without your season pass purchase will not
                    be possible
                  </span>
                  <span className="seasonpass__field-text-required seasonpass__dropzone-format-text">
                    Only in JPEG and PNG Format
                  </span>
                </span>
              </div>
            )}

            <label className="seasonpass__field">
              <span className="seasonpass__field-text">
                <span className="seasonpass__field-text-label">
                  Season Pass Type
                </span>
                {isRequestChanges && !passType && (
                  <span className="seasonpass__field-text-required">
                    Required Field
                  </span>
                )}
              </span>

              <AsyncSelect
                cacheOptions
                defaultOptions
                loadOptions={handleSelectPlace("passType")}
                name="passType"
                value={{ label: passType, value: passType }}
                isDisabled={this.checkIsSelectDisabled("passType")}
                onChange={(value) => this.handleChange("passType", value.value)}
                classNamePrefix="seasonpass__select"
                className="seasonpass__select"
              />
            </label>

            <div className="seasonpass__grouped-fields seasonpass__grouped-fields_selects">
              <label className="seasonpass__field">
                <span className="seasonpass__field-text">
                  <span className="seasonpass__field-text-label">Area</span>
                  {isRequestChanges && !area && (
                    <span className="seasonpass__field-text-required">
                      Required Field
                    </span>
                  )}
                </span>
                <AsyncSelect
                  cacheOptions
                  defaultOptions
                  loadOptions={handleSelectPlace("area")}
                  value={{ label: area, value: area }}
                  name="area"
                  isDisabled={this.checkIsSelectDisabled("area")}
                  onChange={(value) => this.handleChange("area", value.value)}
                  classNamePrefix="seasonpass__select"
                  className="seasonpass__select"
                />
              </label>

              <label className="seasonpass__field">
                <span className="seasonpass__field-text">
                  <span className="seasonpass__field-text-label">Row</span>
                  {isRequestChanges && !row && (
                    <span className="seasonpass__field-text-required">
                      Required Field
                    </span>
                  )}
                </span>
                <AsyncSelect
                  cacheOptions
                  defaultOptions
                  value={{ label: row, value: row }}
                  loadOptions={handleSelectPlace("row")}
                  isDisabled={this.checkIsSelectDisabled("row")}
                  onChange={(value) => this.handleChange("row", value.value)}
                  classNamePrefix="seasonpass__select"
                  className="seasonpass__select"
                />
              </label>

              <label className="seasonpass__field">
                <span className="seasonpass__field-text">
                  <span className="seasonpass__field-text-label">Side</span>
                  {isRequestChanges && !side && (
                    <span className="seasonpass__field-text-required">
                      Required Field
                    </span>
                  )}
                </span>
                <AsyncSelect
                  cacheOptions
                  defaultOptions
                  loadOptions={handleSelectPlace("side")}
                  value={{ label: side, value: side }}
                  isDisabled={this.checkIsSelectDisabled("side")}
                  onChange={(value) => this.handleChange("side", value.value)}
                  classNamePrefix="seasonpass__select"
                  className="seasonpass__select"
                />
              </label>

              <label className="seasonpass__field">
                <span className="seasonpass__field-text">
                  <span className="seasonpass__field-text-label">Seat</span>
                  {isRequestChanges && !seat && (
                    <span className="seasonpass__field-text-required">
                      Required Field
                    </span>
                  )}
                </span>
                <AsyncSelect
                  cacheOptions
                  defaultOptions
                  loadOptions={handleSelectPlace("seat")}
                  value={{ label: seat, value: seat }}
                  isDisabled={this.checkIsSelectDisabled("seat")}
                  onChange={(value) => this.handleChange("seat", value.value)}
                  classNamePrefix="seasonpass__select"
                  className="seasonpass__select"
                />
              </label>
            </div>

            <CheckBox
              checked={isRequestChanges}
              id="request-changes-checkbox"
              name="request-changes-checkbox"
              onChange={this.setIsRequestChanges}
            >
              <span>Request Changes</span>
            </CheckBox>

            {isRequestChanges ? (
              <button
                type="button"
                className="btn-primary seasonpass__btn seasonpass__btn_request-changes"
                disabled={this.checkIsSubbmitDisabled()}
                onClick={this.showConfirmationModal}
              >
                Request Changes
              </button>
            ) : (
              <div className="seasonpass__result-wrapper">
                <div>
                  <div className="seasonpass__terms-and-conditions ">
                    <CheckBox
                      checked={termsAndConditionsChecked}
                      id="terms-and-conditions-checkbox"
                      name="terms-and-conditions-checkbox"
                      onChange={this.setTermsAndConditions}
                    >
                      <span onClick={this.showTermsAndConditionsModal}>
                        I agree to the Terms and Conditions
                      </span>
                    </CheckBox>
                  </div>
                  <button
                    type="button"
                    onClick={this.handleSubmit}
                    className="btn-primary seasonpass__btn"
                    disabled={this.checkIsSubbmitDisabled()}
                  >
                    Buy
                  </button>
                </div>

                {renderTotal({
                  currencyCode: transactionData.currencyCode,
                  total: transactionData.paymentAmount,
                  price,
                })}
              </div>
            )}
            <div className="seasonpass__bank-logos">
              {bankLogos.map(({ alt, src, link }) => (
            <div key={alt}>
                  <a target="_blank" rel="noopener noreferrer" href="{link}">
                    <img style="heigh:50px;" src={src} alt={alt} />
                  </a>
                </div>
          ))}
            </div>
          </form>
        </div>
      </Container>
    );
  }
}

export default withRouter(SeasonPass);
