import React from "react";
import Loader from "react-loaders";

import "./NewLoader.sass";

const NewLoader = ({ isFullScreen, ...props }) => (
    <Loader
      type="ball-scale-ripple-multiple"
      className={(isFullScreen && "fs") + ` ${'loader-default'} ${props.className && ''}`}
    />
);

export default NewLoader;
