import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Container from "../../components/UI/Container/Container";
import PageTitle from "../../components/UI/PageTitle/PageTitle";
import axios from '../../axios-instance';
import { getHeaders } from "../../helpers/getHeaders";


const BalancePage = () => {
    const [state, setState] = useState({
        isLoaded: false,
        balance: 0,
        isPaynlAccount: false,
        name: 'Promoter'
    });

    const token = JSON.parse(localStorage.getItem("user")).token;

    useEffect(() => {
        (async () => {
            const data = await axios.get('/Promoter', {
                headers: getHeaders(token),
            })

            setState(v => {
                return {
                    ...v,
                    ...data.data,
                    isLoaded: true,
                }
            })

        })()
    }, []);

    return <Container className="transactions membership-managment">
        <PageTitle>Balance</PageTitle>

        <section>

            {state.isLoaded
                &&
                <h2>Balance for {state.name}: {(state.balance / 100.0).toFixed(2)} EUR</h2>
            }
        </section>

    </Container>
}

export default withRouter(BalancePage)