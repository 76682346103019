export const paymentTypes = {
    undefined: 0,
    online: 1,
    cash: 2,
    pin: 3,
    generated: 4,
    guest: 5,
    delayedPayment: 6,
}

export const getPaymentTypeLabel = (paymentType: number) => {
    return paymentType == 0 && 'Undefined'
        || paymentType == 1 && 'Online'
        || paymentType == 2 && 'Cash'
        || paymentType == 3 && 'Pin'
        || paymentType == 4 && 'Generated'
        || paymentType == 5 && 'Guest'
        || paymentType == 6 && 'Delayed Payment (Invoice)'
        || '';
}