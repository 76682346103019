import React, { useEffect, useMemo, useState } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import queryString from "query-string";
import axios from "../../axios-instance";
import Select from "react-select";
import "../Transactions/Transactions.sass";
import "./MembershipManagment.sass";
import NewLoader from "../../components/NewLoader/NewLoader";
import ReactTable from "react-table";
import withFixedColumns from "react-table-hoc-fixed-columns";
import PageTitle from "../../components/UI/PageTitle/PageTitle";
import { getHeaders } from "../../helpers/getHeaders";
import Container from "../../components/UI/Container/Container";
import { StatusLabel } from "../../components/Transactions/StatusLabel/StatusLabel";
import { IconButton } from "../../components/UI/IconButton/IconButton";
import { MembershipManagmentAction } from "../../components/MembershipManagment/MembershipManagmentAction/MembershipManagmentAction";
import Warning from "../../images/warning.svg";
import swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { toast } from "react-toastify";
import ClipboardJS from "clipboard";
import Icon from "../../components/UI/Icon/Icon";

const MySwal = withReactContent(swal);

export const membershipStatuses = [
  { value: 1, label: "Created" },
  { value: 2, label: "Pending To Approved" },
  { value: 3, label: "Approved" },
  { value: 4, label: "Restricted" },
];

const MembershipManagment = (params) => {
  const token = params.token;
  const pageAction = queryString.parse(params.location.search).action;
  const pageActionFrom = queryString.parse(params.location.search).actionFrom;
  const pageActionTo = queryString.parse(params.location.search).actionTo;

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [maximizingOptions, setMaximizingOptions] = useState(true);
  const [searchProfileStatus, setSearchProfileStatus] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortingType, setSortingType] = useState(3);

  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(500);
  const [dataToDisplay, setDataToDisplay] = useState([]);

  const ReactTableFixedColumns = useMemo(
    () => withFixedColumns(ReactTable),
    []
  );

  const handleOptionsClick = (e) => setMaximizingOptions((curr) => !curr);

  const showLinkCopied = (link) => {
    ClipboardJS.copy(link);

    toast.dismiss(257);
    toast.success("Link was copied to clipboard", {
      toastId: 257,
    });
  };

  const copyMemberPageLink = (data) => {
    showLinkCopied(
      `${process.env.REACT_APP_PUBLIC_URL}/member?token=${data.accessToken}`
    );
  };

  const editMemberData = (data) => {
    window.open(
      `${process.env.REACT_APP_PUBLIC_URL}/member?token=${data.accessToken}&action=edit`,
      "_blank"
    );
  };

  const manageMemberSP = (data) => {
    window.open(
      `${process.env.REACT_APP_PUBLIC_URL}/member?token=${data.accessToken}&action=selling`,
      "_blank"
    );
  };

  const getData = async () => {
    // setLoading(true);
    const { data } = await axios.post(
      "/MembershipManagment/search",
      {
        searchQuery: searchQuery,
        statuses:
          searchProfileStatus == null ? [] : [searchProfileStatus.value],
        sortingType: sortingType,
        pageSize: 100000 /*pageSize*/,
        page: 0 /*currentPageNumber*/,
      },
      {
        headers: getHeaders(token),
      }
    );

    setData(
      data.list.map((item, i) => {
        const { customer } = item;
        const { id, ...otherFields } = customer;

        return { ...item, row: i + 1, ...otherFields };
      })
    );

    if (
      pageAction == "reject" &&
      !(localStorage.getItem("rejectStarted") == "true")
    ) {
      localStorage.setItem("rejectStarted", true);

      for (var i = 1 * pageActionFrom - 1; i <= 1 * pageActionTo - 1; i++) {
        var item = data[i];

        if (
          data[i].status == 1 &&
          !localStorage.getItem("rejected_id_" + item.id)
        ) {
          const { data: res } = await axios.put(
            "/MembershipManagment/reject-membership-request",
            {
              memberId: item.id,
            },
            {
              headers: getHeaders(token),
            }
          );

          if (res) {
            localStorage.setItem("rejected_id_" + item.id, true);
          }
        }
      }
    }

    if (pageAction == "rejectReset") {
      localStorage.setItem("rejectStarted", false);
      var keys = Object.keys(localStorage),
        j = keys.length;

      while (j--) {
        if (keys[j].startsWith("rejected_id_")) {
          localStorage.removeItem(keys[j]);
        }
      }
    }

    setLoading(false);
  };

  const showApproveModal = (data) => {
    MySwal.fire({
      imageUrl: Warning,
      title: "Confirm Approval of the Request",
      text: "Please note that this is an irreversible action",
      cancelButtonText: "Cancel",
      confirmButtonText: "Approve",
      allowEscapeKey: false,
      customClass: "seasonpass__modal",
      allowOutsideClick: false,
      allowEnterKey: false,
      showConfirmButton: true,
      showCancelButton: true,
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        approveRequest(data);
      }
    });
  };

  const showRejectModal = (data) => {
    MySwal.fire({
      imageUrl: Warning,
      title: "Confirm Reject of the Request",
      text: "Please note that this is an irreversible action",
      cancelButtonText: "Cancel",
      confirmButtonText: "Reject",
      allowEscapeKey: false,
      customClass: "seasonpass__modal",
      allowOutsideClick: false,
      allowEnterKey: false,
      showConfirmButton: true,
      showCancelButton: true,
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        rejectRequest(data);
      }
    });
  };

  const changeSortingType = () => {
    setSortingType((curr) => (curr === 2 ? 3 : 2));
    // getData();
  };

  const changeSearchProfileStatusHandler = (item) => {
    setSearchProfileStatus(item);
    // getData();
  };
  const changeSearchInputHandler = (e) => {
    setSearchQuery(e.target.value);
    // getData();
  };

  const approveRequest = async (data) => {
    const { data: res } = await axios.put(
      "/MembershipManagment/approve-membership-request",
      {
        memberId: data.id,
      },
      {
        headers: getHeaders(token),
      }
    );

    if (res) {
      toast.success("Successfully approved");
      getData();
    }
  };

  const customSelectStyles = {
    control: (base) => ({
      ...base,
      height: "46px",
      "min-height": "46px",
      cursor: "pointer",
    }),
    placeholder: (base) => ({
      ...base,
      color: "#ABABAB",
    }),
  };

  const rejectRequest = async (data) => {
    const { data: res } = await axios.put(
      "/MembershipManagment/reject-membership-request",
      {
        memberId: data.id,
      },
      {
        headers: getHeaders(token),
      }
    );
    if (res) {
      toast.success("Successfully rejected");
      getData();
    }
  };

  const handleCurrentPageChanged = (value) => {
    setCurrentPageNumber(value);
  };

  const handlePageSizeChanged = (value) => {
    setPageSize(value);
  };

  const goToCurrentPage = () => {
    const start = currentPageNumber * pageSize;
    const end = (currentPageNumber + 1) * pageSize;

    setDataToDisplay(data.slice(start, end));
  };

  const refreshDataToDisplay = () => {
    if (currentPageNumber === 0) {
      goToCurrentPage();
    } else {
      setCurrentPageNumber(0);
    }
  };

  useEffect(() => {
    refreshDataToDisplay();
  }, [data, pageSize]);

  useEffect(() => {
    goToCurrentPage();
  }, [currentPageNumber]);

  useEffect(() => {
    getData();
  }, [searchQuery, searchProfileStatus, sortingType]);

  let emptyStateText = "There are no data";

  const getLabelStyle = (state, rowInfo, column) => ({
    style: {
      marginTop: "6px",
    },
  });

  /*if (!data.length && !loading) {
    return <Page404 />;
  }*/

  const columns = [
    {
      Header: "Row",
      accessor: "row",
      resizable: false,
      width: 80,
      getProps: getLabelStyle,
    },
    {
      Header: "First Name",
      accessor: "firstName",
      resizable: false,
      width: 160,
      getProps: getLabelStyle,
    },
    {
      Header: "Last Name",
      accessor: "lastName",
      resizable: false,
      width: 160,
      getProps: getLabelStyle,
    },
    {
      Header: "Email",
      accessor: "email",
      resizable: false,
      width: 280,
      getProps: getLabelStyle,
    },
    {
      Header: () => (
        <div className="payment-status" onClick={changeSortingType}>
          <span>Status</span>
          <Icon name="arrow-up-down" />
        </div>
      ),
      resizable: false,
      width: 205,
      Cell: ({
        row: {
          _original: { status },
        },
      }) => {
        return (
          <StatusLabel statusValue={status} statuses={membershipStatuses} />
        );
      },
    },
    {
      Header: "Phone Number",
      accessor: "phoneNumber",
      resizable: false,
      width: 120,
      getProps: getLabelStyle,
    },
    {
      Header: "City",
      accessor: "city",
      resizable: false,
      width: 120,
      getProps: getLabelStyle,
    },
    {
      Header: "Physical Address",
      accessor: "physicalAddress",
      resizable: false,
      width: 200,
      getProps: getLabelStyle,
    },
    {
      Header: "Postal Code",
      accessor: "postalCode",
      resizable: false,
      width: 100,
      getProps: getLabelStyle,
    },
    {
      Header: "Access Token",
      accessor: "accessToken",
      resizable: false,
      width: 280,
      getProps: getLabelStyle,
    },
    {
      Header: () => {
        const optionsIconName = maximizingOptions
          ? "arrow-right"
          : "arrow-left";

        return (
          <div className="buttons-block options">
            <IconButton
              iconName={optionsIconName}
              className="btn-primary IconButton primary"
              onClick={handleOptionsClick}
            />
          </div>
        );
      },
      Cell: ({ row: { _original: data } }) => (
        <MembershipManagmentAction
          maximizingOptions={maximizingOptions}
          data={data}
          showApproveModal={showApproveModal}
          copyMemberPageLink={copyMemberPageLink}
          editMemberData={editMemberData}
          manageMemberSP={manageMemberSP}
          showRejectModal={showRejectModal}
        />
      ),
      width: maximizingOptions ? 280 : 83,
      fixed: "right",
    },
  ];

  return (
    <Container className="transactions membership-managment">
      <PageTitle>Membership</PageTitle>

      <div className="searchPanel">
        <Select
          isClearable
          options={membershipStatuses}
          placeholder="Select status"
          name="searchProfileStatus"
          classNamePrefix="seasonpass__select"
          value={searchProfileStatus}
          onChange={changeSearchProfileStatusHandler}
          className="seasonpass__select searchPanel__select"
          styles={customSelectStyles}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: "#6071B5",
              primary25: "#F2F4FE",
              primary50: "#F2F4FE",
              primary75: "#F2F4FE",
            },
          })}
        />

        <input
          className="searchPanel__search input-behavior"
          type="text"
          placeholder="Search"
          value={searchQuery}
          onChange={changeSearchInputHandler}
        />
      </div>

      {loading ? (
        <NewLoader />
      ) : data.length ? (
        <>
          <ReactTableFixedColumns
            data={dataToDisplay}
            columns={columns}
            manual
            showPagination={true}
            pageSizeOptions={[100, 200, 500, 1000, 100000]}
            minRows={0}
            page={currentPageNumber}
            pageSize={pageSize}
            pages={Math.ceil(data.length / pageSize)}
            onPageChange={handleCurrentPageChanged}
            onPageSizeChange={handlePageSizeChanged}
            sortable={false}
            getTrProps={() => ({
              style: {
                alignItems: "stretch",
              },
            })}
            // getTableProps={() => ({
            //   onScroll: handleTransactionsTableScroll,
            // })}
          />
        </>
      ) : (
        <div className="events__empty-state">
          <PageTitle>{emptyStateText}</PageTitle>
          <img src="/images/img-empty-events.svg" alt="Empty membership" />
        </div>
      )}
    </Container>
  );
};

const mapStateToProps = ({ auth }) => ({
  auth,
  token: auth.user.token,
});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MembershipManagment)
);
