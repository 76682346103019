import React from 'react';

import styles from './FramedLabel.module.sass';

export const FramedLabel = ({ label, ...props }) => {

    return <section className={`${styles['container']} ${props.className}`}>
        <span className={styles['label']}>{label}</span>
        {props.children}
    </section>
}