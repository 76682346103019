const ROUTES = {
  LOGIN: "/login",
  REGISTRATION: "/register",
  REGISTRATION_SUCCESS: "/register-success",
  RECOVER_PASSWORD: "/forgot-password",
  NEW_PASSWORD: "/new-password",
  EVENTS: "/my-events",
  FAQ: "/faq",
  ADD_EVENT: "/add-event",
  TICKET_DETAIL: "/event/:event_id/ticket/:id",
  ADD_TICKET: "/ticket/add/:id",
  EVENT: "/event/:id",
  EVENT_TEMPLATE_EDITOR: "/event/:id/template-editor",
  SCANTEAMS: "/scanteams",
  PAYMENTS: "/payments",
  SCANTEAMS_ADD: "/scanteams/add",
  SCANTEAMS_VIEW: "/scanteam/:id",
  PROMOTERS: "/promoters",
  ADD_BAR_CODE: "/event/:event_id/ticket/:id/add-bar-code",
  GENERATE_PDFS: "/event/:event_id/ticket/:id/generate-pdfs",
  SEND_BUY_EMAIL: "/event/:event_id/ticket/:id/send-buy-email",
  UPLOAD_AVAILABLE_SEATS: "/event/:id/upload-available-seats",
  STATS: "/event/:event_id/statistics",
  TRANSACTIONS: "/event/:event_id/transactions",
  MEMBERSHIP_MANAGMENT: "/membership-managment",
  BALANCE: "/balance",
  SELL: "/event/:event_id/sell/:id?",
  SEASON_PASS: "/season-pass/:id",
  BUY_TICKETS: "/buy-tickets/:id?",
  PARTIZAN_ONLINE_SELLER: "/partizan-online-seller",
  PARTIZAN_SEASON_PASS: "/partizan-season-pass/:id",
  SELLING: "/events/:slug/:id?",
  EVENTS_LIST_TO_SELLING: "/events/list/:iframeSlug",
  SELLING_OFFERS: "/offers/:transactionId",
  SEASON_PASS_MEMBER: "/SeasonPassMember/:lang?",
  MEMBER: "/member",
  LOVEFEST: "/lovefest/:id?",
  ONLINE_SELLER: "/online-seller",
  APPROVE_REQUESR: "/approve-request/:id",
  ORDERS: "/orders",
  DASHBOARD: "/dashboard",
  TEST: "/test",
  RESELLING_REQUEST: "/reselling-request/:eventId",
  STATISTICS: "/statistics",
  STATS_SCANNING: "/statistics/scanning",
  STATS_SELLING: "/statistics/selling",
};

export default ROUTES;
