import React from "react";
import Icon from "../Icon/Icon";

export const IconButton = ({
  iconName,
  textLeft = false,
  textRight = false,
  text = "",
  ...props
}) => {
  return (
    <button {...props}>
      {textLeft && text}
      <Icon name={iconName} className={props.className} />
      {textRight && text}
    </button>
  );
};
