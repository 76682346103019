import { isNfcTicket, isNoSeatTicket, isSeatsIoTicket } from "./ticketHelper.ts";

const ensureOriginalPriceSaved = (tickets: any[]) => {
    for (const ticket of tickets) {

        if (!isSeatsIoTicket(ticket) && !('price' in ticket)) {
            ticket.price = 0;
        }

        if (!('originalPrice' in ticket)) {
            ticket.originalPrice = !!ticket.price ? ticket.price : ticket.pricing.price
            if (ticket.fixedDiscount) {
                ticket.originalPrice -= ticket.fixedDiscount;
            }
        }
    }
}

export const getOriginalPrice = (tickets: any[], coupon: any | undefined | null = null) => {
    let price = 0;

    ensureOriginalPriceSaved(tickets);

    let noSeatPriceReducer = (a, ticket, i) => a + ticket.originalPrice * ticket.count;

    if (coupon) {
        noSeatPriceReducer = (a, ticket, i) => {
            let fixedDiscount = 0;
            if (!coupon.ticketTypeIds || coupon.ticketTypeIds.length === 0) {
                fixedDiscount = coupon.fixedDiscount;
            }
            else if (coupon.ticketTypeIds && coupon.ticketTypeIds.filter((v) => (v === ticket.ticketTemplateId)).length > 0 && coupon.ticketTypeIds.filter((v) => (v === ticket.ticketTemplateId)).length <= ticket.count) {
                fixedDiscount = coupon.fixedDiscount;
            }

            const firstTimeCouponDiscount = ((i === 0)
                ? (ticket.price * (ticket.count > 2 ? 2 : ticket.count) * coupon.discount / 100)
                : 0)

            return a
                + ticket.price * ticket.count
                - firstTimeCouponDiscount
                - fixedDiscount;
        };
    }

    price += tickets.filter(t => isNoSeatTicket(t)).reduce(noSeatPriceReducer, 0);

    price += tickets.filter(t => isNfcTicket(t)).reduce((a, ticket) => {
        return a + ticket.originalPrice * ticket.count;
    }, 0);

    price += tickets.filter(t => isSeatsIoTicket(t)).reduce((a, ticket) => {
        return a + +ticket.originalPrice;
    }, 0);

    return price;
}

export const getPrice = (tickets: any[], manualDiscount: number): number => {
    const price = getOriginalPrice(tickets) - manualDiscount;
    return +(price > 0 ? price : 0).toFixed(2);
}

export const recalculatePrice = (tickets: any[], fixedDiscount: number = 0) => {
    ensureOriginalPriceSaved(tickets);

    let prevDiscount = fixedDiscount;

    for (const ticket of tickets) {
        const count = ticket.count || 1;

        const [nextPrice, discount] = getNewPrice(ticket.originalPrice, prevDiscount, count);
        prevDiscount = discount;

        if (ticket.pricing) {
            ticket.pricing.price = +(nextPrice).toFixed(2)
        }
        else {
            ticket.price = +(nextPrice).toFixed(2);
        }
    }
}

const getNewPrice = (originalPrice: number, discount: number, count: number) =>
    (originalPrice * count) >= discount
        ? [((originalPrice * count) - discount) / count, 0]
        : [0, discount - (originalPrice * count)]